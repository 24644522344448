import React, {Fragment} from "react";
import {Alert,TextLink} from "@myob/myob-widgets";
import "../../../../common/Common.css"

function SelectionMessage({availableFeeds, availableSelections, bankfeedLimit, isOverFeedLimit, reachSelectionLimit, handleManageFeed, handleUpgrade, source}){
  const isFromOpenBanking = source?.toLowerCase() === "OpenBanking".toLowerCase();
  const isFromBNZ  = source?.toLowerCase() === "ProprietaryAPI".toLowerCase();

  const messages = {
    default: {
      type: "info",
      content: (
        <Fragment>
          {isFromOpenBanking ? <>
          <strong>Connect up to {bankfeedLimit} accounts</strong>
          <p>{availableSelections} connection(s) remaining. We will not save data from accounts you do not connect.</p>
          </> : <p>Your subscription includes up to {bankfeedLimit} bank account connections. You have {availableFeeds} more available.</p>}
          {isFromBNZ && (<p><b>Can't see the correct bank account?</b> You may not have access to the relevant accounts.
            <TextLink tone="info" href="https://www.myob.com/nz/support/myob-business/banking/bank-feeds/setting-up-bank-feeds/connecting-a-bnz-account-to-myob?productview=browser#cant-see-the-account-you-want-to-connect" target="_blank">
              Learn about account access
            </TextLink>
          </p>)}
        </Fragment>
      )
    },
    unlimited: {
      type: "info",
      content: (
        <Fragment>
          <p>Select which accounts you'd like to set up bank feeds for. Your subscription has an unlimited number of feeds.</p>
          {isFromBNZ && (<p><b>Can't see the correct bank account?</b> You may not have access to the relevant accounts.
            <TextLink tone="info" href="https://www.myob.com/nz/support/myob-business/banking/bank-feeds/setting-up-bank-feeds/connecting-a-bnz-account-to-myob?productview=browser#cant-see-the-account-you-want-to-connect" target="_blank">
              Learn about account access
            </TextLink>
          </p>)}
        </Fragment>
      )
    },
    overLimit: {
      type: isFromOpenBanking ? "warning" : "danger",
      content: isFromOpenBanking ? <p>You can connect up to {bankfeedLimit} accounts to MYOB. We won't save any data for the accounts you don't choose, and they won't be available in MYOB.</p> :
        <p>You have reached your {bankfeedLimit} bank feeds limit. Upgrade your subscription for unlimited bank and credit card accounts <a onClick={handleUpgrade} onKeyDown={handleUpgrade}>here</a>.
        Alternatively, remove an existing bank feed in <a onClick={handleManageFeed} onKeyDown={handleManageFeed}>Manage bank feeds</a>.</p>
    },
    reachLimit: {
      type: "warning",
      content: (
        <Fragment>
          <strong>Account limit reached</strong>
          <p>You can connect up to {bankfeedLimit} accounts. To add different accounts, remove a connection first. We will not save data from accounts you do not connect.</p>
        </Fragment>
      )
    }
  }

  const unlimitedPlan = bankfeedLimit === null;
  const message = unlimitedPlan ? messages.unlimited : isFromOpenBanking && reachSelectionLimit ? messages.reachLimit : isOverFeedLimit ? messages.overLimit : messages.default;
  return (
    <Alert type={message.type} inline>
      {message.content}
    </Alert>
  )
}

export default SelectionMessage
